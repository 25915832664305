/* =============================================================================
   ICON (components/_icon.scss)
   ========================================================================== */

.c-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: .9375rem;
	height: .9375rem;
	background-color: color( extra, white );
	border-radius: .0625rem;

	&--medium {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: .125rem;
	}

	&--large {
		width: 2.8125rem;
		height: 2.8125rem;
		border-radius: .1875rem;
	}
}

.c-icon__asset {
	display: block;
	max-width: 50%;
	height: auto;
	color: currentColor;

	svg {
		display: block;
		width: 100%;
	}
}
