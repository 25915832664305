/* =============================================================================
   TABLE (components/_table.scss)
   ========================================================================== */

.c-table {
	margin-bottom: 0;

	thead {
		background-color: #ebf8fd;
		border-radius: 3px;
		border-bottom: 25px solid color( extra, white );

		th {
			font-weight: normal;
			font-size: font-size( xs );
			padding: rem( 8px ) 0;
			color: #949494;
			&:first-child {
				padding-left: 10px;
			}
		}
	}

	tr {
		td {
			padding: rem( 12px ) 0;
			border-bottom: 1px solid color( primary, grey-50 );

			strong {
				font-weight: 500;
			}

			p {
				margin-bottom: 0;
				font-size: font-size( xs );
			}
			&:first-child {
				padding-left: 10px;
			}
		}

		&:first-child {
			td {
				padding-top: 0;
			}
		}

		&:last-child {
			td {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}

	a {
		color: color( extra, black );
	}

	.c-button--primary {
		color: color( extra, white );
	}
}
