/* =============================================================================
   BUTTONS (components/_buttons.scss)
   ========================================================================== */

.c-button {
	display: inline-block;
	min-width: 11.25rem;
	padding: .6875rem 1.5rem;
	text-align: center;
	font-size: font-size( sm );
	font-weight: normal;
	border-radius: rem( 2px );
	transition: all $transition-speed;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&.is-disabled {
		cursor: default;
		pointer-events: none;
	}
}



/**
* Color variations
*/

.c-button--primary {
	background-color: color( primary );
	border: 1px solid color( primary );
	color: color( extra, white );

	&:hover,
	&:focus,
	&:active {
		color: color( extra, white );
		background-color: color-darken( primary );
		border-color: color-darken( primary );
	}

	&.is-disabled {
		color: color( secondary, blue );
	}
}

a.c-button--secondary,
.c-button--secondary {
	background-color: transparent;
	border: 1px solid color( primary, grey-200 );
	color: color( extra, black );

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		border-color: color( extra, black );
	}
}

.c-button--white {
	background-color: color( extra, white );
	border: 1px solid color( extra, white );
	color: color( extra, black );

	&:hover,
	&:focus,
	&:active {
		background-color: color( primary, grey-50 );
		border-color: color( primary, grey-50 );
		color: color( extra, black );
	}
}



/**
* Size Variations
*/

.c-button--small {
	padding-top: .625rem;
	padding-bottom: .625rem;
	font-size: font-size( xs );
}



/**
* Icons
*/

.c-button__icon {
	height: .75em;
	width: .75em;
	margin-left: .3125rem;
	margin-right: .3125rem;
}
