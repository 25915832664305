/* =============================================================================
   HEADER (components/_header.scss)
   ========================================================================== */

.c-header {
	background: color( extra, white );
	box-shadow: 0 .06215rem .125rem 0 rgba( color( extra, black ), .05 );
	position: relative;
	z-index: 999;

	&--transparent {
		background-color: transparent;
		box-shadow: none;
	}
}

.c-header__inner {
	display: flex;
	align-items: center;
	height: 5rem;

	@include media-breakpoint-down( sm ) {
		height: 3.75rem;
	}
}

.c-header__logo {
	flex-shrink: 0;
}

.c-header__menu {
	margin-left: auto;
	height: inherit;

	> ul {
		display: flex;
		align-items: center;
		height: inherit;
		margin-bottom: 0;

		> li {
			height: inherit;
			position: relative;

			// Profile menu item
			&:last-child {
				padding-left: 2.5rem;

				&::before {
					content: '';
					width: .0625rem;
					height: 50%;
					background-color: color( primary, grey-50 );
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY( -50% );
				}
			}

			+ li {
				margin-left: 2.5rem;
			}

			// avatar + dropdown wrapper element
			> div {
				display: flex;
				align-items: center;
				height: inherit;
				position: relative;
			}

			> a {
				display: flex;
				align-items: center;
				height: inherit;
				font-size: font-size( sm );
				color: color( extra, black );
				opacity: .8;
				transition: opacity $transition-speed;

				&::after {
					content: '';
					height: .1875rem;
					width: 100%;
					background-color: currentColor;
					position: absolute;
					bottom: 0;
					left: 0;
					opacity: 0;
					transition: opacity $transition-speed;
				}

				&:hover {
					opacity: 1;
				}
			}

			&.is-active,
			&.is-child-active {
				> a {
					opacity: 1;

					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	@include media-breakpoint-down( sm ) {
		display: none;
	}
}

.c-header__dropdown {
	min-width: 7.5rem;
	padding: .75rem 1.25rem;
	margin-bottom: 0;
	background-color: color( extra, white );
	border-radius: .125rem;
	box-shadow: 0 .06215rem .125rem 0 rgba( color( extra, black ), .05 );
	position: absolute;
	left: 50%;
	top: calc( 100% + .625rem );
	transform: translateX( -50% );

	.has-submenu > & {
		visibility: hidden;
		opacity: 0;
		transition: opacity .4s, visibility 0s;
		transition-delay: 0s, .4s;
	}

	.has-submenu:hover > & {
		visibility: visible;
		opacity: 1;
		transition-delay: 0s, 0s;
	}

	&::before {
		content: '';
		width: 0;
		height: 0;
		border-left: .625rem solid transparent;
		border-right: .625rem solid transparent;
		border-bottom: .625rem solid color( extra, white );
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX( -50% );
	}

	li {
		padding-bottom: .3125rem;

		+ li {
			padding-top: .3125rem;
			border-top: 1px solid color( primary, grey-50 );
		}

		a {
			display: inline-block;
			font-size: font-size( xs );
			color: color( extra, black );
			white-space: nowrap;
			opacity: .8;
			transition: opacity $transition-speed;

			&:hover {
				opacity: 1;
			}
		}

		&.is-active {
			a {
				opacity: 1;
			}
		}
	}
}

.c-header__count {
	position: absolute;
	right: rem( -18px );
	margin-top: rem( -8px );
	color: #fff;
	background-color: #ea1e1e;
	border-radius: 2px;
	font-size: rem( 9px );
	padding: rem( 3px ) rem( 4px ) rem( 2px ) rem( 4px );
	font-weight: 500;
	line-height: 1;
}
