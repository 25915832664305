/* =============================================================================
   BORDERS (utilities/_borders.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/* Border Width
   ========================================================================== */

/**
 * Utilities for controlling the width an element's borders.
 */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-border-t-1 {
			border-top: 1px solid !important;
		}

		.u-border-r-1 {
			border-right: 1px solid !important;
		}

		.u-border-b-1 {
			border-bottom: 1px solid !important;
		}

		.u-border-l-1 {
			border-left: 1px solid !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-border-t-1\@#{$breakpoint-key} {
				border-top: 1px solid !important;
			}

			.u-border-r-1\@#{$breakpoint-key} {
				border-right: 1px solid !important;
			}

			.u-border-b-1\@#{$breakpoint-key} {
				border-bottom: 1px solid !important;
			}

			.u-border-l-1\@#{$breakpoint-key} {
				border-left: 1px solid !important;
			}
		}
	}
}



/* Border Color
   ========================================================================== */

/**
 * Utilities for controlling the color of an element's borders.
 */

@each $color-priority, $color-priority-colors in $colors {
	@each $color-name, $color-value in $color-priority-colors {
		$selector-suffix: $color-priority;

		@if ($color-name != default) {
			$selector-suffix: $color-priority + '-' + $color-name;
		}

		@if ($color-priority == extra) {
			$selector-suffix: $color-name;
		}

		.u-border-#{"" + $selector-suffix} {
			border-color: color( $color-priority, $color-name ) !important;
		}
	}
}
