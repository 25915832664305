/* =============================================================================
   TYPOGRAPHY (settings/_typography.scss)
   ========================================================================== */

/* Base
   ========================================================================== */

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

$font-size-base: 16px !default;
$line-height-base: 1.5 !default;
$font-family-base: 'Rubik', sans-serif !default;


/* Font Sizes
   ========================================================================== */

/**
 * Headings sizes used in elements and utilities layer.
 */

$font-sizes-headings: (
	h1: 1.625rem, // 26
	h2: 1.5rem,   // 24
	h3: 1.375rem, // 22
	h4: 1.25rem,  // 20
	h5: 1.125rem, // 18
	h6: 1rem,     // 16
) !default;

/**
 * Other sizes used in utilities layer for `font-size` utilities.
 */

$font-sizes-other: (
	xxs: .625rem,
	xs: .75rem,
	sm: .875rem,
	base: 1rem,
	md: 1.125rem,
) !default;

/**
 * Merge all font size maps into one.
 */

$font-sizes: map-merge(
	$font-sizes-headings, $font-sizes-other
) !default;
