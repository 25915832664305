/* =============================================================================
   ILLUSTRATION (components/_illustration.scss)
   ========================================================================== */

.c-illustration {
	position: relative;
	transition: 3s all ease-in-out;
	pointer-events: none;

	// Variations
	&--guides {
		.c-illustration__asset {
			// sun bg
			&:first-child {
				width: 100%;
				position: relative;
				z-index: 1;
			}

			// ppl
			&:last-child {
				width: 100%;
				max-width: 75%;
				position: absolute;
				left: 50%;
				bottom: 0;
				z-index: 2;
				transform: translateX( -50% );
			}
		}
	}

	&--basic-scenery {
		position: absolute;
		left: 0;
		right: 0;
	}

	&--help {
		width: 100%;
		max-width: 39.6875rem;
		position: absolute;
		right: 66%;
		top: 8.75rem;

		.c-illustration__text-bubble {
			width: 15rem;
			padding: 1.25rem;
			background-color: color( extra, white );
			border-radius: .9375rem;
			box-shadow: 0 .125rem .25rem rgba( color( extra, black ), .1 );
			position: absolute;
			top: -1.875rem;
			left: 80%;
			z-index: 10;

			p {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				background-color: color( extra, white );
				width: .875rem;
				height: .875rem;
				display: block;
				transform: rotateY( 0deg ) rotate( 45deg );
				position: absolute;
				z-index: 30;
				left: -.25rem;
				top: 3.25rem;
				box-shadow: -.0625rem .0625rem .0625rem( color( extra, black ), .03 );
			}
		}
	}

	&--climber {
		position: absolute;
		left: 0;
		right: 0;

		.c-illustration__asset {
			// mountain bg
			&:first-child {
				width: 100%;
				position: relative;
				z-index: 1;
				animation: 5s ease-out 0s 1 background-animation;
			}

			// mountain climber
			&:last-child {
				width: 100%;
				position: absolute;
				left: 0;
				z-index: 2;
				animation: 5s ease-out 0s 1 background-animation;
			}
		}
	}

	&--campsite {
		width: 100%;
		max-width: 70%;
		margin-top: -5%;
		position: absolute;
		right: 0;

		.c-illustration__asset {
			// mountain bg
			&:first-child {
				width: 100%;
				position: relative;
				z-index: 1;
			}

			// camp
			&:last-child {
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 2;
			}
		}
	}

	&--progress {
		display: flex;
		width: 100%;
		max-width: 62.5rem;
		position: absolute;
		left: 50%;
		transform: translateX( -50% );
		z-index: 5;

		div {
			// tourist
			&:first-child {
				margin-top: -3.75rem;
				max-width: 6.25rem;
				width: 100%;
			}

			// track
			&:nth-child( 2 ) {
				flex-grow: 1;
			}

			// logs
			&:nth-child( 3 ) {
				width: 100%;
				max-width: 3.75rem;
				margin-top: rem( -30px );

				svg:first-child {
					transform: scale( 0 );
					transform-origin: bottom center;
					margin-bottom: rem( -3px );
					transition: transform 1s ease 1s;
				}
			}

			// tents
			&:last-child {
				width: 100%;
				max-width: 5rem;
				margin-top: 2.25rem;
				margin-left: .625rem;
				opacity: .5;
			}
		}
	}

	&--move-up svg {
		margin-top: rem( -140px );
		z-index: -1;
		position: relative;
	}
}

// Bg animations
@include animation-mixin(
	$name: background-animation,
	$start: 20%,
	$left-start: 1.25rem,
	$left-end: 0,
	$opacity-start: 0,
	$opacity-end: 1,
	$scale-start: 1,
	$scale-end: 1
);
