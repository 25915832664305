/* =============================================================================
   POST (components/_post.scss)
   ========================================================================== */

.c-post {
	padding: 2rem 2rem 2rem 6.25rem;
	background-color: color( extra, white );
	border-radius: .25rem;
	box-shadow: 0 .125rem .3125rem rgba( color( extra, black ), .08 );
	position: relative;

	& + & {
		margin-top: 2rem;
	}
}

.c-post__category {
	position: absolute;
	top: 2rem;
	left: 2rem;
}

.c-post__header {
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
	border-bottom: 1px solid color( primary, grey-50 );

	@include media-breakpoint-up( md ) {
		display: flex;
		justify-content: space-between;
	}
}

.c-post__title {
	margin-top: -.25rem;
	margin-bottom: rem( 5px );
	color: color( extra, black );
}

.c-post__meta {
	font-size: font-size( xxs );
	color: color( primary, grey-150 );

	span {
		margin-top: .625rem;
		display: block;
	}

	a {
		display: inline-block;
		color: currentColor;

		&:hover {
			color: color( extra, black );
		}
	}

	svg {
		margin-right: rem( 6px );
	}

	@include media-breakpoint-up( md ) {
		display: flex;
		align-items: center;

		span {
			margin-top: 0;

			&:not( :first-child ) {
				margin-left: 1.25rem;
			}
		}
	}
}

.c-post__rating {
	margin-bottom: 0;
	font-size: font-size( xxs );
	font-weight: 500;
	text-transform: uppercase;

	@include media-breakpoint-down( sm ) {
		margin-top: 1.25rem;
	}
}

.c-post__comments {
	padding-top: 1.25rem;
	border-top: 1px solid color( primary, grey-50 );
}

.c-post__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0 -1.6875rem -1.6875rem -5.9375rem;
	padding: .5rem 1.6875rem .5rem 5.9375rem;
	background-color: color( primary, grey-0 );
	font-size: font-size( xxs );
	color: color( primary, grey-150 );

	a {
		display: inline-block;
		color: currentColor;

		&:not( :first-child ) {
			margin-left: 1.875rem;
		}

		&:hover {
			color: color( extra, black );
		}
	}

	svg {
		margin-right: rem( 6px );
	}
}

.c-post__images {
	img {
		cursor: zoom-in;
	}
}
