/* =============================================================================
   AVATAR (components/_avatar.scss)
   ========================================================================== */

.c-avatar {
	flex-shrink: 0;
	border-radius: 100%;
	background-color: color( primary, grey-50 );
	padding-top: .625rem;
	height: 3.75rem;
	width: 3.75rem;
	position: relative;
	display: block;

	.avatar-default_svg__c-avatar-default {
		fill: color( extra, white );
	}

	// Style variations
	&--border {
		border: .3125rem solid color( extra, white );
		transition: all .1s ease-in-out;

		&.c-avatar--white {
			border-color: color( primary, grey-50 );
		}
	}

	&--white {
		background-color: color( extra, white );

		.avatar-default_svg__c-avatar-default {
			fill: color( primary, grey-50 );
		}
	}

	&--active {
		border-color: color( primary );
		box-shadow: 0 .125rem .25rem rgba( color( extra, black ), .08 );
	}

	// Size variations

	// 20
	&--extra-small {
		padding-top: .125rem;
		height: 1.25rem;
		width: 1.25rem;
	}

	// 32
	&--small {
		padding-top: .25rem;
		height: 2rem;
		width: 2rem;
	}

	// 80
	&--large {
		padding-top: .625rem;
		height: 5rem;
		width: 5rem;
	}

	// 160
	&--extra-large {
		padding-top: 1.25rem;
		height: 10rem;
		width: 10rem;
	}
}

.c-avatar__icon {
	max-width: 33.33333%;
	opacity: .5;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
}
