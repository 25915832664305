/* =============================================================================
   WEBKIT (components/_webkit.scss)
   ========================================================================== */

.c-webkit {
	margin-top: 1.875rem;
	margin-bottom: 1.875rem;
	background-color: color( extra, white );
}

.c-webkit-icon {
	display: flex;
	box-shadow: 0 .125rem .375rem rgba( color( extra, black ), .08 );
	padding: 2rem .625rem;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1.25rem;
	border-radius: .125rem;
	border: 1px solid color( primary, grey-200 );
	transition: all $transition-speed-faster;

	&:hover {
		box-shadow: 0 3px 10px rgba( 0, 0, 0, .12 );
	}

	svg, img {
		width: 1.875rem;
		height: 1.875rem;
	}

	p {
		margin-bottom: 0;
		margin-top: .625rem;
		font-size: font-size( xs );
		text-align: center;
	}
}

.c-webkit-icon__box {
	height: 1.875rem;
	width: 1.875rem;
}
