/* =============================================================================
   Z-INDEX (utilities/_z-index.scss)
   ========================================================================== */

/**
 * Utilities for controlling the stack order of an element.
 */

/* stylelint-disable declaration-no-important */

.u-z-0 {
	z-index: 0 !important;
}

.u-z-10 {
	z-index: 10 !important;
}

.u-z-auto {
	z-index: auto !important;
}
