/* =============================================================================
   NOTIFICATION (components/_notification.scss)
   ========================================================================== */

.c-notification {
	border-radius: 3px;
	padding: rem( 10px ) rem( 16px ) rem( 8px ) rem( 16px );
	margin-bottom: 2rem;
	position: relative;
	display: flex;
	justify-content: space-between;

	p {
		color: color( extra, white );
		margin-bottom: 0;
		line-height: 1.6;
	}

	a {
		color: color( extra, white );
		text-decoration: underline;
	}

	li {
		color: color( extra, white );
		list-style: disc inside;
	}

	&__close {
		border-radius: rem( 3px );
		background-color: rgba( 0, 0, 0, 0 );
		cursor: pointer;
		display: block;
		height: rem( 24px );
		width: rem( 24px );
		text-align: center;
		transition: all .1s ease-in-out;
		margin-top: rem( -2px );

		svg {
			transform: rotate( 45deg ); // rotating the '+' icon
			transition: color .2s;
			width: rem( 12px );
			height: rem( 12px );
		}

		&:hover {
			background-color: rgba( 0, 0, 0, .3 );
		}
	}

	.c-button {
		height: rem( 24px );
		font-size: font-size( xs );
		text-decoration: none;
		padding: rem( 2px ) 0;
		margin-left: rem( 20px );
		background-color: color( extra, white );
		border-color: color( extra, white );
		color: #333;
		align-self: center;
	}

	&--error {
		background-color: color( extra, error );
	}

	&--success {
		background-color: color( extra, success );
	}

	&--warning {
		background-color: color( extra, warning );
	}

	&--fixed {
		position: fixed;
		z-index: 1000;
		bottom: 10px;
		min-width: rem( 600px );
		width: auto;
		margin: 0;
		left: 50%;
		transform: translateX( -50% );
	}
}
