/* =============================================================================
   RESULTS SUMMARY (components/_results-summary.scss)
   ========================================================================== */

.c-results-summary {
	padding-left: 2.5rem;
	position: relative;

	& + & {
		margin-top: 1.875rem;
	}
}

.c-results-summary__order {
	width: 1.5625rem;
	height: 1.5625rem;
	background-color: color( extra, black );
	border-radius: 50%;
	font-size: font-size( xs );
	line-height: 1.6875rem;
	font-weight: 500;
	text-align: center;
	color: color( extra, white );
	position: absolute;
	left: 0;
	top: 0;
	transition: background-color .2s ease;

	.c-results-summary--active & {
		background-color: color( primary, pink );
	}
}


.c-results-summary__icon {
	width: 2.5rem;
	height: 2.5rem;
	opacity: .3;
	position: absolute;
	left: -.9375rem;
	top: -.9375rem;

	svg {
		display: block;
		width: 100%;
		height: auto;
		color: currentColor;
	}
}

.c-results-summary__heading {
	margin-bottom: 0;
	font-weight: 500;

	&:first-child {
		margin-bottom: .625rem;
		font-weight: normal;
	}

	// svg icon wrapper when within the heading
	span {
		display: inline-block;
		width: 1.25rem;
		margin-right: .625rem;
		opacity: .3;
	}

	.c-results-summary__icon + & {
		margin-bottom: .5rem;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: .0625rem;
			margin-top: .3125rem;
			background-color: currentColor;
			opacity: .25;
		}
	}
}

.c-results-summary__list {
	margin-bottom: 0;
	font-size: font-size( base );

	li {
		margin-top: rem( 5px );
		margin-bottom: rem( -3px );

		&:empty {
			width: 70%;
			margin-top: rem( 12px );
			margin-bottom: rem( 3px );
			height: .875rem;
			background-color: #eaeaea;
			border-radius: .125rem;
		}
	}

	p {
		margin-bottom: 0;
		font-size: inherit;
		color: currentColor;
	}
}
