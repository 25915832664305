/* =============================================================================
   FLEXBOX (utilities/_flexbox.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/**
 * Utilities for controlling how flex items shrink.
 */

.u-flex-shrink-0 {
	flex-shrink: 0 !important;
}



/**
 * Utilities for controlling how flex items grow.
 */

.u-flex-grow-1 {
	flex-grow: 1 !important;
}

