/* =============================================================================
   OFFCANVAS (components/_offcanvas.scss)
   ========================================================================== */

.c-offcanvas__content {
	height: 100%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	overflow: scroll;
}

.c-offcanvas__menu {
	ul {
		margin-bottom: 0;

		li {
			+ li {
				margin-top: .75rem;
			}

			a {
				display: inline-block;
				color: color( extra, black );
				opacity: .8;
				transition: opacity $transition-speed;

				&:hover {
					opacity: 1;
				}
			}

			ul {
				margin-left: .9375rem;

				li {
					margin-top: .3125rem;
				}

				a {
					font-size: font-size( sm );
				}
			}

			&.is-active,
			&.is-child-active {
				> a {
					font-weight: 500;
					opacity: 1;
				}
			}
		}
	}
}
