/* =============================================================================
   FORM (components/_form.scss)
   ========================================================================== */

.c-form__merge-elements {
	@include media-breakpoint-up( sm ) {
		// side by side fields
		display: flex;
		align-items: flex-start;

		.c-form__element:first-child {
			flex-grow: 1;

			input[ type ] {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				&:not( :focus ) {
					border-right-color: transparent;
				}
			}
		}

		.c-form__element:last-child {
			flex-grow: 1;

			input[ type ] {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				&:not( :focus ) {
					border-left-color: transparent;
				}
			}
		}

		input[ type ] {
			box-shadow: none;
		}

		.c-button {
			margin-top: 1.5rem; // margin top to offset the missing label
			border-radius: 0 .125rem .125rem 0;
		}
	}
}



/* Form Elements
   ========================================================================== */

.c-form__element {
	margin-bottom: 1.25rem;

	// Framed variation
	&--framed {
		padding: .6875rem 1rem;
		background-color: color( extra, white );
		border: 1px solid transparent;
		border-radius: .125rem;
		box-shadow: 0 .0625rem .3125rem rgba( color( extra, black ), .09 );
		cursor: pointer;
		transition: all $transition-speed ease-in-out;

		&:hover {
			box-shadow: 0 .125rem .25rem rgba( color( extra, black ), .15 );
			border-color: color( primary );
		}

		&.c-form__element--focus {
			background-color: #eefaff;
			border: 1px solid color( primary );
			box-shadow: 0 .0625rem .3125rem rgba( 180, 220, 230, 1 );

			&:hover {
				box-shadow: 0 .125rem .5rem rgba( 180, 220, 230, 1 );
			}
		}

		&.c-form__element--lg {
			padding: .8rem 1.6rem;
		}

		&[ class*='u-bg-' ]:not( :hover ) {
			box-shadow: none;
		}
	}
}



/* Form Lables
   ========================================================================== */

.c-form__label {
	margin-bottom: .5rem;
}



/* Form Fields
   ========================================================================== */

.c-form__field {
	position: relative;

	&--checkbox,
	&--radio {
		position: relative;
		text-align: left;

		input {
			display: none;

			+ label {
				display: inline-block;
				padding-left: 1.75rem;
				font-size: font-size( sm );
				cursor: pointer;
				position: relative;

				small {
					padding-left: 1.875rem;
				}

				&::before {
					content: '';
					display: block;
					position: absolute;
					top: .3em;
					left: 0;
					width: .75rem;
					height: .75rem;
					border-radius: .0625rem;
					color: color( extra, black );
					background-color: color( extra, white );
					background-position: 50% 0%;
					border: 1px solid color( primary, grey-200 );
					transition: all $transition-speed;
				}
			}

			&:active,
			&:checked {
				+ label {
					&::before {
						border-color: color( primary );
					}
				}
			}
		}
	}

	&--radio {
		input {
			+ label {
				&::before {
					border-radius: 50%;
				}
			}

			&:checked {
				+ label {
					&::before {
						border: 4px solid color( primary );
					}
				}
			}
		}
	}

	&--checkbox {
		input {
			&:checked {
				+ label {
					&::before {
						border-color: color( primary );
						background: color( extra, white ) url( '../../assets/icons/checkmark.svg' ) 50% 50% no-repeat;
					}
				}
			}
		}
	}

	&--textarea {
		textarea {
			resize: none;
		}
	}

	// Right alignment
	.c-form__element--right & {
		&--checkbox,
		&--radio {
			input {
				+ label {
					padding-left: 0;
					padding-right: 1.75rem;

					&::before {
						left: auto;
						right: 0;
					}
				}
			}
		}
	}

	// Framed
	.c-form__element--framed & {
		&--checkbox,
		&--radio {
			input {
				+ label {
					display: block;
				}
			}
		}
	}

	// Large
	.c-form__element--lg & {
		&--checkbox,
		&--radio {
			input {
				+ label {
					padding-left: 2.5rem;
					font-size: font-size( base );

					&::before {
						width: 1rem;
						height: 1rem;
						top: .2em;
					}
				}
			}
		}

		&--textarea {
			textarea {
				height: 25rem;
			}
		}
	}

	&:focus,
	&--focus {
		outline: none;
		border: 1px solid color( primary );
		box-shadow: 0 .0625 .1875rem rgba( 180, 220, 230, 1 );

		&::placeholder {
			color: transparent;
		}
	}
}


/* Multi Select field
   ========================================================================== */

.c-form__multi-select-field {
	font-size: font-size( sm );

	&__control {
		min-height: 2.5rem !important;
		box-shadow: 0 .0625rem .125rem rgba( 45, 45, 45, .05 );
		border-radius: .125rem !important;

		&:hover {
			border-color: color( primary ) !important;
		}
	}

	&__value-container {
		padding-left: rem( 12px ) !important;
	}

	&__placeholder {
		color: $color-base !important;
	}

	&__multi-value {
		background-color: color( primary ) !important;
		color: #fff;

		&__label {
			color: #fff !important;
		}

		&__remove:hover {
			background-color: color-darken( primary ) !important;
			color: #fff !important;
		}
	}

	&__input input {
		outline: none;
		height: 1rem;
	}
}

/* Form Icons
   ========================================================================== */

.c-form__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.5rem;
	width: .75rem;
	position: absolute;
	top: 50%;
	left: .75rem;
	z-index: 1;
	opacity: .2;
	pointer-events: none;
	transform: translateY( -50% );

	svg,
	img {
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	~ input,
	~ textarea {
		padding-left: 2rem;
	}

	// If the icon container is empty cancel the padding
	&:empty {
		~ input,
		~ textarea {
			padding-left: .75rem;
		}
	}

	// Second icon container positioned to the end of the form
	&:nth-child( 2 ) {
		left: auto;
		right: .75rem;

		~ input,
		~ textarea {
			padding-right: 2rem;
		}

		// If the icon container is empty cancel the padding
		&:empty {
			~ input,
			~ textarea {
				padding-right: .75rem;
			}
		}
	}

	&--has-tooltip {
		pointer-events: all;
		transition: opacity .2s;

		&:hover {
			opacity: .4;
		}
	}

	// Icons for checkbox/radio fields
	.c-form__field--checkbox &,
	.c-form__field--radio & {
		right: 0;
		left: auto;

		~ input + label {
			padding-right: 1.25rem;
		}

		.c-form__element--right & {
			left: 0;
			right: auto;

			~ input + label {
				padding-left: 1.25rem;
			}
		}
	}
}



/* Form validations
   ========================================================================== */

.c-form__error-list {
	margin-top: .25rem;
	margin-bottom: 0;
	color: color( extra, error );

	li {
		font-size: .75rem;
	}
}

.c-form__element--error {
	input, textarea, select {
		border-color: color( extra, error );

		&:hover {
			border-color: color-darken( extra, error );
		}

		& + label {
			&::before {
				border-color: color( extra, error );
			}
		}

		&:not( :checked ) + label {
			&:hover {
				&::before {
					border-color: color-darken( extra, error );
				}
			}
		}

		&:focus {
			outline: none;
			border: 1px solid color-darken( extra, error );
			box-shadow: 0 1px 3px color( extra, error );

			&::placeholder {
				color: transparent;
			}
		}
	}
}

