/* =============================================================================
   MODAL (components/_modal.scss)
   ========================================================================== */

.c-modal__overlay {
	padding: $grid-gutter;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba( color( extra, black ), .3 );
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;

	&--still {
		background: color( primary, grey-25 );
	}
}

.c-modal__container {
	width: 100%;
	padding: 2.5rem;
	max-width: 53.75rem;
	max-height: calc( 100% - 1.25rem );
	background-color: color( extra, white );
	box-shadow: 0 .125rem .3125rem rgba( color( extra, black ), .08 );
	border-radius: .25rem;
	overflow-y: auto;
	position: relative;
	animation: fadeIn .3s forwards;

	&:focus {
		outline: none;
	}

	.c-avatar {
		&:hover {
			cursor: pointer;
			transform: scale( 1.05 );
		}

		&--active {
			transform: scale( 1.05 );
			animation: selectPop .2s;
		}
	}
}

.c-modal__close {
	display: block;
	width: 1rem;
	height: 1rem;
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	cursor: pointer;
	transform: rotate( 45deg ); // rotating the '+' icon
	transition: color .2s;

	&:hover {
		color: color( primary, grey-150 );
	}

	svg {
		display: block;
		width: 100%;
		height: auto;
		pointer-events: none;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY( 30px );
	}

	to {
		opacity: 1;
		transform: translateY( 0 );
	}
}

@keyframes selectPop {
	50% { transform: scale( 1 ); }

	100% { transform: scale( 1.05 ); }
}
