/* =============================================================================
   TYPOGRAPHY (utilities/_typography.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/**
 * Utilities for controlling font weight of an element.
 */

.u-text-medium {
	font-weight: 500 !important;
}
