/* =============================================================================
   ACCORDION (components/_accordion.scss)
   ========================================================================== */

.c-accordion__item {
	padding: 1.25rem;
	background-color: color( extra, white );
	border-radius: .25rem;
	box-shadow: 0 .125rem .3125rem rgba( color( extra, black ), .08 );

	& + & {
		margin-top: 1rem;
	}
}


.c-accordion__heading {
	margin-bottom: 0;
	padding-right: 1.875rem;
	cursor: pointer;
	position: relative;
}

.c-accordion__icon {
	display: block;
	width: .625rem;
	height: .625rem;
	position: absolute;
	right: 0;
	top: 0;

	.is-active & {
		svg {
			path {
				&:last-child {
					display: none;
				}
			}
		}
	}
}

.c-accordion__content {
	display: none;
	margin-top: 1.25rem;
	padding-top: 1.25rem;
	border-top: 1px solid color( primary, grey-50 );

	> *:last-child {
		margin-bottom: 0;
	}

	.is-active & {
		display: block;
	}
}
