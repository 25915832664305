/* =============================================================================
   COLORS (settings/_colors.scss)
   ========================================================================== */

/*
 * Colors that are most represented in the design, every project must contain at
 * least one primary color.
 */

$color-primary: (
	primary: (
		default: #30b3e4, // blue
		pink: #f934e2,
		yellow: #f7e151,
		grey-0: #f8f8f8,
		grey-25: #f1f1f1,
		grey-50: #ededed,
		grey-100: #a6a6a6,
		grey-150: #595959,
		grey-200: #cfcfcf,
	)
) !default;

/*
 * Colors that complement primary palette, every project must contain at least
 * one secondary color.
 */

$color-secondary: (
	secondary: (
		default: #ffdffb,
		blue: #c9e9f5,
		blue-dark: #5ab3d4,
		pink: #ffdffb,
		pink-dark: #d66dca,
		yellow: #fff1cb,
		yellow-dark: #d0c166,
		red: #ffd7d7,
		red-dark: #f25454,
	)
) !default;

/**
 * Colors used on notifications or interactive elements/forms with dynamic
 * states.
 */

$color-extra: (
	extra: (
		black: #2d2d2d,
		white: #fff,
		links: #0097e6,
		success: #49c781,
		info: #c0e1f0,
		warning: #ceca59,
		error: #f25454,
	)
) !default;

/**
 * Merge all color maps into one.
 */

$colors: map-merge(
	map-merge( $color-primary, $color-secondary ), $color-extra
) !default;

/**
 * Colors used for base things such as base text color and links color.
 *
 * Don't use `color()` mixin in following variables, they're not available in
 * this (settings) layer.
 */

$color-base: color( extra, black ) !default;
$color-links: color( extra, links ) !default;

/**
 * Set a specific jump point for requesting color jumps.
 */

$color-shade-percentage: 15% !default;
