.o-svg-icon {
	// Flow with text content
	display: inline-block;

	// Vertically align icon with adjacent text
	vertical-align: middle;

	// Use the parent font-size for width and height
	height: 1em;
	width: 1em;

	// Align more nicely with capital letters
	position: relative;
	top: -.0625em;

	// Fix event.target issue
	// https://www.smashingmagazine.com/2018/05/svg-interaction-pointer-events-property/
	pointer-events: none;
	fill: none;
	stroke: currentColor;
}

.o-svg-fill {
	fill: currentColor;
	stroke: none;
}

.o-svg-up {
	/* default */
	transform: rotate( 0deg );
}

.o-svg-right {
	transform: rotate( 90deg );
}

.o-svg-down {
	transform: rotate( 180deg );
}

.o-svg-left {
	transform: rotate( -90deg );
}
