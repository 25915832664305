/* =============================================================================
   PAGINATION (components/_pagination.scss)
   ========================================================================== */

.c-pagination {
	display: flex;
	justify-content: center;
	margin-top: rem( 50px );
}

.c-pagination__list {
	display: flex;
	align-items: center;
	font-size: font-size( sm );
	line-height: 1;
}

.c-pagination__list-item {
	&--page-link {
		margin: 0 rem( 10px );

		a {
			padding: rem( 6px ) rem( 12px );
			cursor: pointer;
			transition: all $transition-speed ease-in-out;
			border: 1px solid transparent;
			color: color( primary, grey-150 );

			&:hover {
				background-color: color( extra, white );
				border: 1px solid color( primary, grey-200 );
				border-radius: .125rem;
				box-shadow: 0 .0625rem .3125rem rgba( color( extra, black ), .09 );
				color: color( extra, black );
			}
		}

		&.is-active {
			a {
				background-color: color( extra, white );
				border: 1px solid transparent;
				border-radius: .125rem;
				box-shadow: 0 .0625rem .3125rem rgba( color( extra, black ), .09 );
				color: color( primary );
			}
		}
	}

	&--dots {
		color: color( primary, grey-200 );
		cursor: default;
	}

	&--prev-link,
	&--next-link {
		a {
			color: color( primary, grey-200 );
			font-size: font-size( xs );

			&:hover {
				color: color( extra, black );
			}
		}

		svg {
			max-width: rem( 8px );
			margin: 0 rem( 6px );
		}
	}

	&--prev-link {
		margin-right: rem( 10px );
	}

	&--next-link {
		margin-left: rem( 10px );
	}
}
