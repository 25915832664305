button, input, optgroup, select, textarea {
	font-family: $font-family-base;
	font-weight: inherit;
	color: inherit;
}

input[ type='text' ],
input[ type='password' ],
input[ type='time' ],
input[ type='date' ],
input[ type='datetime-local' ],
input[ type='month' ],
input[ type='week' ],
input[ type='email' ],
input[ type='number' ],
input[ type='search' ],
input[ type='tel' ],
input[ type='url' ],
input[ type='file' ],
select,
textarea {
	appearance: none;
	outline: none;
	display: block;
	width: 100%;
	height: 2.5rem;
	padding: .5rem .75rem;
	line-height: 1.25;
	font-size: font-size( sm );
	font-weight: normal;
	color: $color-base;
	background-color: color( extra, white );
	border: 1px solid color( primary, grey-200 );
	border-radius: .125rem;
	box-shadow: 0 .0625rem .125rem rgba( color( extra, black ), .05 );
	transition: all $transition-speed ease-out;

	&:hover,
	&:focus,
	&:active {
		border-color: color( primary );
	}

	&.c-form__element--focus,
	&:focus {
		outline: none;
		border: 1px solid color( primary );
		box-shadow: 0 .0625rem .1875rem rgba( 180, 220, 230, 1 );

		&::placeholder {
			color: transparent;
		}
	}

	&::placeholder {
		color: color( primary, gray );
		opacity: 1;
	}
}

select {
	padding-right: 2.4375rem;
	background-image: url( '../../assets/icons/select-arrow.svg' );
	background-position: center right 1rem;
	background-repeat: no-repeat;
	background-size: .4375rem;

	&:hover {
		cursor: pointer;
	}

	&[ multiple ] {
		background-color: color( extra, white );
		height: 5rem;
	}

	&::-ms-expand {
		display: none;
	}
}

textarea {
	height: 6rem;
	line-height: 1.5;
}

label {
	display: block;
	font-size: .6875rem;
	transition: all $transition-speed ease-out;
}

legend {
	display: block;
	margin-bottom: .25rem;
	font-size: .6875rem;
	font-weight: 900;
	line-height: 1.5;
	color: #999;
}

form {
	p,
	li {
		font-size: .875rem;
	}
}
