/* =============================================================================
   SPACING (settings/_spacing.scss)
   ========================================================================== */

/**
 * Control the default spacing of most elements by modifying these variables.
 * You can add more entries to the $spacers map, should you need more variation.
 */

$spacer-base: 1.25rem !default;

$spacers: (
	'n5': -3.125rem,
	'n2': -1.25rem,
	'0': 0,
	'1': .625rem,
	'2': 1.25rem,
	'3': 1.875rem,
	'4': 2.5rem,
	'5': 3.125rem,
	'6': 3.75rem,
	'7': 4.375rem,
	'8': 5rem,
	'9': 5.625rem,
	'10': 6.25rem,
) !default;
