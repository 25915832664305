/* =============================================================================
   CARD (components/_card.scss)
   ========================================================================== */

.c-card {
	padding: 2rem;
	background-color: color( extra, white );
	border-radius: .25rem;
	box-shadow: 0 .125rem .3125rem rgba( color( extra, black ), .08 );
	position: relative;
	z-index: 10;

	&.c-table-wrap{
		padding: 0;
		padding-bottom:2rem;
	}

	& + & {
		margin-top: 2rem;
	}

	&--full-height {
		height: 100%;
	}

	&--blue-gradient {
		background: radial-gradient( #7394d5, #395791 );
	}
}

.c-card__title {
	margin: -2rem -2rem 2rem -2rem;
	padding: 1.8rem 2rem;
	border-radius: .25rem .25rem 0 0;
	background-color: color( primary );
	font-size: 1.375rem;
	color: color( extra, white );
}

.c-card__illustration {
	height: 10.9375rem;
	margin: -4rem -1.25rem 1.5625rem -1.25rem;
	border-bottom: 1px solid color( primary, grey-50 );
	position: relative;

	svg {
		width: 100%;
		max-height: 100%;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX( -50% );
	}
}
