/* =============================================================================
   FILETERS (components/_filters.scss)
   ========================================================================== */

.c-filters {
	margin-bottom: 0;
	margin-left: -.5rem;
	margin-right: -.5rem;
}

.c-filters__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .3125rem .5rem;
	border: 1px solid transparent;
	border-radius: .25rem;
	cursor: pointer;
	transition: border-color $transition-speed,
				background-color $transition-speed;

	&:hover,
	&--active {
		background-color: color( primary, grey-25 );
		border-color: color( primary, grey-50 );
	}

	& + & {
		margin-top: .25rem;
	}
}

.c-filters__count {
	font-size: font-size( xs );
	color: color( primary, grey-100 );
	transition: color $transition-speed;

	.c-filters__item:hover &,
	.c-filters__item--active & {
		color: color( extra, black );
	}
}
