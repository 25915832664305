/* =============================================================================
   DIVIDER (components/_divider.scss)
   ========================================================================== */

.c-divider {
	display: block;
	margin-top: .625rem;
	height: .125rem;
	width: 2.8125rem;
	background-color: color( primary, grey-200 );
}
