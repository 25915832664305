/* =============================================================================
   ANIMATION (tools/_animation.scss)
   ========================================================================== */

@mixin animation-mixin($name, $start, $left-start, $left-end, $opacity-start, $opacity-end, $scale-start, $scale-end) {
	@keyframes #{$name} {
		0% {
			left: $left-start;
			opacity: $opacity-start;
			transform: scale( $scale-start );
		}

		#{($start)} {
			left: $left-start;
			opacity: $opacity-start;
			transform: scale( $scale-start );
		}

		100% {
			left: $left-end;
			opacity: $opacity-end;
			transform: scale( $scale-end );
		}
	}
}
