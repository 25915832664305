/* =============================================================================
   TYPOGRAPHY (elements/_typography.scss)
   ========================================================================== */

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

h1, h2 {
	line-height: 1.25;
}

h3, h4 {
	line-height: 1.4;
}

h5, h6 {
	line-height: 1.5;
	margin-bottom: rem( 12px );
}

p {
	font-size: font-size( sm );
	line-height: 1.75;
	color: color( primary, grey-150 );

	strong {
		font-weight: 500;
		color: $color-base;
	}
}

a {
	transition: color $transition-speed;

	&:hover {
		color: color-darken( extra, links );
	}
}

li {
	list-style: none;

	p:last-child {
		margin-bottom: rem( 10px );
	}
}

small {
	font-size: font-size( xs );
	line-height: 1.3;
	color: color( primary, grey-150 );
}

blockquote {
	font-size: font-size( sm );
	line-height: 1.75;
	font-style: italic;
	color: color( primary, grey-150 );
}
