/* =============================================================================
   FOOTER (components/_footer.scss)
   ========================================================================== */

.c-footer {
	margin-top: 3.75rem;
}

.c-footer__inner {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	border-top: 1px solid color( primary, grey-50 );

	@include media-breakpoint-up( sm ) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.c-footer__copy {
	font-size: font-size( xs );
	color: color( primary, grey-200 );

	@include media-breakpoint-up( sm ) {
		margin-bottom: 0;
	}
}

.c-footer__logo {
	opacity: .2;
	transition: .2s all ease-in-out;

	svg {
		width: rem( 54px );
		height: rem( 20px );
	}

	&:hover {
		opacity: 1;
	}
}
