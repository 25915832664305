/* =============================================================================
   LINK (components/_link.scss)
   ========================================================================== */

// Different link color/hover variations

.c-link {
	display: inline-block;

	&--grey {
		color: color( primary, grey-150 );

		&:hover {
			color: color( extra, black );
		}
	}

	&--black {
		color: color( extra, black );

		&:hover {
			color: color-darken( extra, black );
		}
	}
}

a.is-disabled {
	filter: grayscale( 100% );
	opacity: .1;
	cursor: default;
	transition: all .1s ease-in-out;
}
